<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Reviews
        </strong>
        <hwa-button
        v-if="$can('reorder-reviews', 'hwa')"
          icon="RefreshCwIcon"
          label="Reorder"
          @onClick="()=>{showReorderModal=true}"
        />
      </div>
      <b-table
        small
        :fields="fields"
        :items="reviews"
        responsive="sm"
      >
        <template #cell(action)="data">
          <b-button
            :disabled="!$can('approve-reviews', 'hwa')"
            :tool-tip-text="data.item.approved?'Approved, click to disapprove':'Not approved, click to approve'"
            :variant="data.item.approved?'flat-success':'flat-danger'"
            @click="approveReview(data.item)"
          ><feather-icon :icon="!data.item.approved?'UserXIcon':'UserCheckIcon'" /></b-button>
        </template>
        <template #cell(roles)="data">
          <div
            v-for="(role,index) in data.item.roles"
            :key="index"
            class="px-1"
          >
            <b-badge variant="light-success">
              {{ role.name }}
            </b-badge>
          </div>
        </template>
      </b-table>
    </b-card>
    <hwa-modal
      title="Reorder"
      size="md"
      :show="showReorderModal"
      ok-text="Save"
      :loading="reviewLoading"
      @onOk="handleReorder()"
      @onClose="()=>{showReorderModal=false}"
    >
      <draggable
        v-model="reviews"
        class="list-group list-group-flush cursor-move"
      >
        <b-list-group-item
          v-for="(review, index) in reviews"
          :key="index"
        >
          <b-card :title="review.reviewer">
            {{ review.review }}
          </b-card>
        </b-list-group-item>
      </draggable>
    </hwa-modal>
  </div>
</template>

<script>
import {
  BTable, BCard, BBadge, BListGroupItem, BButton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver } from 'vee-validate'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaModal from '@/layouts/components/HwaModal.vue'
import confirm from '@/mixins/confirm'
import HwaSelect from '@/layouts/components/HwaSelect.vue'

export default {
  components: {
    HwaSelect,
    HwaModal,
    HwaInput,
    HwaButton,
    BTable,
    BCard,
    BBadge,
    ValidationObserver,
    draggable,
    BListGroupItem,
    BButton,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      fields: ['review', {key:'reviewer_name',label:"Reviewer",sortable:true,},{key:"rating",label:"Rating",sortable:true}, 'action'],
      reviews: [],
      reviewLoading: false,
      showReorderModal: false,
    }
  },
  mounted() {
    this.getReviews()
  },
  methods: {
    handleReorder() {
      this.reviewLoading = true
      // eslint-disable-next-line camelcase
      const new_order = this.reviews.map((r, index) => ({ id: r.id, order: index }))
      this.$http.post('/api/reorder', { new_order })
        .then(res => {
          this.reviewLoading = false
          this.showReorderModal = false;
          this.showSuccess('Reorder was successful')
        })
        .catch(err => {
          this.reviewLoading = false
          this.showError('Failed to reorder review')
        })
    },
    approveReview(review) {
      // if (this.review_id) {
      // this.reviewLoading = true
      this.$http.put('/api/approve-review', { id: review.id, approved: !review.approved })
        .then(res => {
          this.reviewLoading = false
          const rev = this.reviews.find(r => +r.id === +review.id)
          rev.approved = !review.approved
        })
        .catch(err => {
          this.reviewLoading = false
          this.showError('Failed to activate review')
        })
        // } else {
        //   this.showInfo('Select a review')
        // }
    },
    getReviews() {
      this.$http.get('/api/reviews')
        .then(res => {
          this.reviews = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve reviews')
        })
    },
  },
}
</script>

<style>
    .list-group-item {
         border: 0px solid rgba(34, 41, 47, 0.125) !important;
    }
</style>
